/* eslint-disable no-param-reassign */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import * as gqlQuery from '../components/submission/lab-coc/queries';
import SubmissionService from '../logic/SubmissionService';
import LocalDbService from '../logic/LocalDbService';
import useWebService from '../hooks/useWebService';
import useDbCacheProvider from '../hooks/useDbCacheProvider';

export const CocFormContext = createContext({
  getForm: () => 'context not defined',
  clearForm: () => 'context not defined',
  changeForm: () => 'context not defined',
  setSampleTestValue: () => 'context not defined',
  getFormValue: () => 'context not defined',
  submitCoc: () => 'context not defined',
  calculateTests: () => 'contesct not defined',
  setHoldStatus: () => 'context not defined',
  setIsHoldFromLab: () => 'context not defiend',
  initializeFormTo: () => 'context not defined',
});

function useCocFormProvider({ children }) {
  const { getGqlClient, mutate } = useWebService();
  const gqlClient = getGqlClient();
  const { getDbCache } = useDbCacheProvider();

  const defaultForm = {
    testsForSamples: [],
    province: '',
    turnAroundTime: 'Seven days',
    invoiceName: '',
    reportName: '',
    additionalReportName: '',
    invoiceAddress: '',
    reportAddress: '',
    additionalReportAddress: '',
    invoicePhone: '',
    reportPhone: '',
    additionalReportPhone: '',
    invoiceEmail: '',
    reportEmail: '',
    additionalReportEmail: '',
    currentLab: '',
    sampleTests: [],
  };

  const [form, setForm] = useState(defaultForm);
  const getForm = () => form;

  const clearForm = () => {
    setForm(defaultForm);
  };

  const initializeFormTo = (newForm) => {
    setForm(newForm);
  };

  const changeForm = (field, newValue) => {
    const newForm = { ...form };
    newForm[field] = newValue;
    setForm(newForm);
  };

  const getIndexOfSampleTest = (sampleLocationId, top, bottom) => form
    .testsForSamples.findIndex((testsForSample) => (
      testsForSample.boreholeId === sampleLocationId
      && testsForSample.top === top
      && bottom === testsForSample.bottom
    ));

  const setSampleTestValue = (testList, sampleLocationId, top, bottom) => {
    let list = '';
    // eslint-disable-next-line no-return-assign
    testList.forEach((test) => list = list === ''
      ? list.concat(`${test.value}`)
      : list.concat(`,${test.value}`));

    const newTestsForSamples = [...form.testsForSamples];
    const matchedT4S = getIndexOfSampleTest(sampleLocationId, top, bottom);
    if (matchedT4S === -1) {
      newTestsForSamples.push({
        testList: list,
        boreholeId: sampleLocationId,
        top,
        bottom,
        isHold: true,
        isHoldFromLab: false,
      });
    } else {
      newTestsForSamples[matchedT4S].testList = list;
    }
    changeForm('testsForSamples', newTestsForSamples);
  };

  const setHoldStatus = (isHold, sampleLocationId, top, bottom) => {
    const newTestsForSamples = [...form.testsForSamples];
    const matchedT4S = getIndexOfSampleTest(sampleLocationId, top, bottom);
    if (matchedT4S === -1) {
      newTestsForSamples.push({
        testList: '',
        boreholeId: sampleLocationId,
        top,
        bottom,
        isHold,
        isHoldFromLab: false,
      });
    } else {
      newTestsForSamples[matchedT4S].isHold = isHold;
    }
    return changeForm('testsForSamples', newTestsForSamples);
  };

  const setIsHoldFromLab = (isHoldFromLab, sampleLocationId, top, bottom) => {
    const newTestsForSamples = [...form.testsForSamples];
    const matchedT4S = getIndexOfSampleTest(sampleLocationId, top, bottom);
    if (matchedT4S === -1) {
      newTestsForSamples.push({
        testList: '',
        boreholeId: sampleLocationId,
        top,
        bottom,
        isHold: false,
        isHoldFromLab,
      });
    } else {
      newTestsForSamples[matchedT4S].isHoldFromLab = isHoldFromLab;
      newTestsForSamples[matchedT4S].testList = '';
      newTestsForSamples[matchedT4S].isHold = false;
    }
    return changeForm('testsForSamples', newTestsForSamples);
  };

  const submitBorehole = (
    borehole,
    updateSubmissionList,
    setErrorMessage,
  ) => SubmissionService(
    // eslint-disable-next-line no-underscore-dangle
    borehole._id,
    getDbCache(),
    mutate,
    gqlClient,
  ).then((result) => {
    // eslint-disable-next-line no-param-reassign
    borehole.submitted = new Date().toISOString();
    // eslint-disable-next-line no-param-reassign
    borehole.id = result;
    LocalDbService.saveWithRev(borehole).then(() => {
      updateSubmissionList();
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
      setErrorMessage('Error saving locally. Do not retry submitting this report.');
    });
    // eslint-disable-next-line no-underscore-dangle
    return { id: result, _id: borehole._id };
  });

  const submitCoc = async ({
    setErrorMessage,
    returnToAllBoreholeLogs,
    updateSubmissionList,
    boreholes,
    siteId,
    finalSubmit,
  }) => {
    const boreholeIds = boreholes.map((borehole) => {
      if (borehole.submitted !== undefined && borehole.sampleLocationId !== '') {
        return borehole.sampleLocationId;
      }
      return submitBorehole(
        borehole,
        updateSubmissionList,
        setErrorMessage,
      );
    });

    Promise.all(boreholeIds).then(async (results) => {
      const ids = results.map((result) => result.id || result);
      gqlClient.query(
        gqlQuery.getSubmissionAndDataByIdList,
        { ids },
        { fetchPolicy: 'no-cache' },
      ).then((submissions) => {
        const testsAndIds = form.testsForSamples.map((t4s) => {
          let match = null;
          if (t4s.isHoldFromLab) {
            return null;
          }
          submissions.data.getSubmissionAndDataByLocationIdList.forEach((sub) => {
            const matchedSample = sub.fieldSamples.find((sample) => ((
              sample.sampleLocationId === t4s.boreholeId
                || sub.sampleLocation.sampleLocName === t4s.boreholeId)
                && parseFloat(t4s.top) === sample.depthTop));
            if (matchedSample) {
              match = {
                id: matchedSample.id,
                testList: t4s.testList,
                isHoldFromLab: t4s.isHold,
              };
            }
          });
          return match;
        });
        const cocSubmissionObject = {
          submit: finalSubmit,
          smaSamples: testsAndIds.map((pair) => pair.id),
          smaSampleTests: testsAndIds.map((pair) => pair.testList),
          holdsFromLabs: testsAndIds.map((testContent) => !!testContent.isHold),
          invoiceName: form.invoiceName,
          reportName: form.reportName,
          additionalReportName: form.additionalReportName,
          invoiceAddress: form.invoiceAddress,
          reportAddress: form.reportAddress,
          additionalReportAddress: form.additionalReportAddress,
          invoicePhone: form.invoicePhone,
          reportPhone: form.reportPhone,
          additionalReportPhone: form.additionalReportPhone,
          invoiceEmail: form.invoiceEmail,
          reportEmail: form.reportEmail,
          additionalReportEmail: form.additionalReportEmail,
          lab: form.currentLab,
          province: form.province,
          turnAroundTime: form.turnAroundTime,
          siteId,
        };
        debugger;
        if (form.id) {
          gqlClient.mutate(
            gqlQuery.updateCocLab,
            {
              ...cocSubmissionObject,
              cocId: form.id,
            },
          ).then((response) => {
            if (response.data) {
              setErrorMessage('');
              returnToAllBoreholeLogs();
              return;
            }
            setErrorMessage('FAILED TO SUBMIT');
            // eslint-disable-next-line no-undef
            window.scrollTo(0, 0);
          });
        } else {
          gqlClient.mutate(
            gqlQuery.createCocLab,
            cocSubmissionObject,
          ).then((response) => {
            if (response.data) {
              setErrorMessage('');
              returnToAllBoreholeLogs();
              return;
            }
            setErrorMessage('FAILED TO SUBMIT');
            // eslint-disable-next-line no-undef
            window.scrollTo(0, 0);
          });
        }
      });
    });
  };

  const getFormValue = (field) => form[field];

  const calculateTests = (tests) => tests.filter((test) => test.company === form.currentLab
    && test.province === form.province);

  return (
    <CocFormContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        getForm,
        changeForm,
        clearForm,
        setSampleTestValue,
        setIsHoldFromLab,
        getFormValue,
        submitCoc,
        calculateTests,
        setHoldStatus,
        initializeFormTo,
      }}
    >
      {children}
    </CocFormContext.Provider>
  );
}

useCocFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default useCocFormProvider;
