import React, { useEffect, useState } from 'react';
import useWebService from '../../hooks/useWebService';
import CocFormProvider from '../../Providers/cocFormProvider';
import CocPage from '../submission/lab-coc/CoCPage';
import UnsubmittedCocRow from '../UnsubmittedCocRow/UnsubmittedCocRow';
import useFormProvider from '../../hooks/useFormProvider';
import ConvertFromSubmitObjToState from '../../logic/ConvertFromSubmitObjToState';

import { getUnsubmittedCocLabs } from './queries';
import { getSubmissionAndData } from '../submission/queries';

function UnsubmittedCocsPage() {
  const { getGqlClient } = useWebService();
  const gqlClient = getGqlClient();
  const { initialize } = useFormProvider();
  const [unsubmittedCocs, setUnsubmittedCocs] = useState([]);
  const [cocEditData, setCocEditData] = useState();
  const [boreholesForForm, setBoreholesForForm] = useState();
  const [submissions, setSubmissions] = useState();
  const [showLabCoc, setShowLabCoc] = useState(false);

  const fetchUnsubmitted = async () => {
    const result = await gqlClient.query(
      getUnsubmittedCocLabs,
      {},
      { fetchPolicy: 'no-cache' },
    );
    const submissionsAndData = await gqlClient.query(
      getSubmissionAndData,
      {
        isAdmin: false,
        siteIds: result.data.getUnsubmittedCocLabs.map((lab) => lab.siteId),
      },
      { fetchPolicy: 'no-cache' },
    );
    setSubmissions(submissionsAndData.data.getSubmissionAndData);
    setUnsubmittedCocs(result.data.getUnsubmittedCocLabs);
  };

  useEffect(() => {
    fetchUnsubmitted();
  }, []);

  const formatCocData = (formPulledState) => {
    const output = {
      ...formPulledState.cocLab,
      currentLab: formPulledState.cocLab.lab,
      siteName: formPulledState.siteName,
      siteId: formPulledState.siteId,
      testsForSamples: formPulledState.fieldSamples.map((fieldSample) => {
        const tests = formPulledState.cocLabFieldSamples.filter((labFieldSample) => labFieldSample
          .fieldSampleId === fieldSample.id);
        let testsAsCSVs = '';
        tests.forEach((test) => {
          if (testsAsCSVs.length === 0) {
            testsAsCSVs = testsAsCSVs.concat(test.testId);
          } else {
            testsAsCSVs = testsAsCSVs.concat(`,${test.testId}`);
          }
        });
        const fieldSampleList = {
          testList: testsAsCSVs,
          top: fieldSample.depthTop,
          bottom: fieldSample.depthBottom,
          isHold: tests.length > 0 ? tests.isHold : true,
          isHoldFromLab: tests.length > 0 ? tests.isHold : true,
          boreholeId: fieldSample.sampleLocationId,
        };
        return fieldSampleList;
      }),
    };
    return output;
  };

  const cocClick = (unsubmittedCoc) => {
    setShowLabCoc(true);
    const cocPageBoreholeList = submissions.filter((sub) => sub.site.id === unsubmittedCoc.siteId);
    const formatBoreholes = cocPageBoreholeList
      .map((borehole) => ConvertFromSubmitObjToState(borehole));
    setBoreholesForForm(formatBoreholes);
    setCocEditData(formatCocData(unsubmittedCoc));
  };

  const onToggleLabCoc = () => {
    fetchUnsubmitted();
    setShowLabCoc(!showLabCoc);
  };

  return (
    <div className="UnsubmittedCocs">
      <h3>Unsubmitted COCS</h3>
      {!showLabCoc && (
        unsubmittedCocs.map((unsubmittedCoc) => (
          <UnsubmittedCocRow
            unsubmittedCoc={unsubmittedCoc}
            cocClick={cocClick}
            key={unsubmittedCoc.id}
          />
        ))
      )}
      {!showLabCoc && unsubmittedCocs.length === 0 && (
        <span>No Unsubmitted Cocs</span>
      )}
      {showLabCoc && (
        <CocFormProvider>
          <CocPage
            boreholes={boreholesForForm}
            siteName={cocEditData.siteName}
            initialize={initialize}
            initialState={cocEditData}
            toggleLabCoC={onToggleLabCoc}
            updateSubmissionList={() => {}}
            siteId={cocEditData.siteId}
          />
        </CocFormProvider>
      )}
    </div>
  );
}

export default UnsubmittedCocsPage;
