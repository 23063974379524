import { useContext } from 'react';
import { CocFormContext } from '../Providers/cocFormProvider';

export default () => {
  const {
    getForm,
    changeForm,
    clearForm,
    setSampleTestValue,
    setIsHoldFromLab,
    getFormValue,
    submitCoc,
    calculateTests,
    initializeFormTo,
    setHoldStatus,
  } = useContext(CocFormContext);
  return {
    getForm,
    changeForm,
    clearForm,
    setSampleTestValue,
    setIsHoldFromLab,
    getFormValue,
    submitCoc,
    calculateTests,
    initializeFormTo,
    setHoldStatus,
  };
};
