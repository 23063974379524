import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';
import useCocForm from '../../../hooks/useCocForm';
import './FieldSamples.css';

function FieldSample(props) {
  const {
    fieldSample,
    tests,
    renderSampleLabelAndValue,
    borehole,
  } = props;
  const {
    setSampleTestValue,
    setHoldStatus,
    setIsHoldFromLab,
    getFormValue,
  } = useCocForm();
  const testsForSamples = getFormValue('testsForSamples');
  const selectOptions = [];
  const newtests = [...tests];

  const {
    depthTop,
    depthBottom,
    bag,
    jar,
    vial,
    sample,
    ec,
    ova,
    comment,
  } = fieldSample;

  const matchedT4S = testsForSamples.findIndex((testsForSample) => ((
    testsForSample.boreholeId
    && testsForSample.boreholeId === borehole.sampleLocationId)
    || (testsForSample.boreholeId === borehole.borehole))
    && testsForSample.top === fieldSample.depthTop
    && testsForSample.bottom === fieldSample.depthBottom);

  const getTestsById = (ids) => {
    const filteredTests = newtests.filter((test) => ids.includes(test.id.toString()));
    const formattedTests = filteredTests.map((test) => (
      { value: test.id, label: test.officialName }
    ));
    return formattedTests;
  };

  const getTestsAssigned = () => {
    if (matchedT4S === -1) {
      return [];
    }

    const testsById = getTestsById(testsForSamples[matchedT4S].testList.split(','));

    return testsById;
  };

  const testsAssigned = getTestsAssigned();

  newtests.forEach((test) => {
    const optionData = { value: test.id, label: test.officialName };

    if (testsAssigned.filter((assigned) => assigned.value === optionData.value).length === 0) {
      selectOptions.push(optionData);
    }
  });

  const onListChange = (event, newValue) => {
    // eslint-disable-next-line no-unused-vars
    const temp = [
      ...newValue.filter((option) => !newtests.includes(option)),
    ];
    setSampleTestValue(
      temp,
      borehole.sampleLocationId || borehole.borehole,
      depthTop,
      depthBottom,
    );
  };

  return (
    <div className="fieldSampleItem" key={depthTop}>
      <div className="fieldSampleDataPoint">
        <span className="fieldSampleLabel">Name:</span>
        <span className="fieldSampleHeader">{sample}</span>
      </div>
      <div className="fieldSampleContents">
        <div className="fieldSampleRow">
          {renderSampleLabelAndValue('Top', depthTop)}
          {renderSampleLabelAndValue('Bottom', depthBottom)}
          {renderSampleLabelAndValue('EC (dS/m)', ec)}
        </div>
        <div className="fieldSampleRow">
          {renderSampleLabelAndValue('VOC (ppm)', ova)}
          {renderSampleLabelAndValue('Bag', bag)}
          {renderSampleLabelAndValue('Jar', jar)}
          {renderSampleLabelAndValue('Vial', vial)}
        </div>
        <span>
          {renderSampleLabelAndValue('Comment', comment)}
        </span>
        <span className="holdForLabLabel">
          Hold for lab
        </span>
        <Checkbox
          onChange={(event) => setHoldStatus(
            event.target.checked,
            borehole.sampleLocationId || borehole.borehole,
            depthTop,
            depthBottom,
          )}
          checked={matchedT4S !== -1 ? testsForSamples[matchedT4S].isHold : true}
          disabled={matchedT4S !== -1 ? testsForSamples[matchedT4S].isHoldFromLab : false}
        />
        <span className="holdFromSubmit">
          Hold from submitting
        </span>
        <Checkbox
          value={matchedT4S !== -1 ? testsForSamples[matchedT4S].isHoldFromLab : false}
          onChange={(event) => {
            setIsHoldFromLab(
              event.target.checked,
              borehole.sampleLocationId || borehole.borehole,
              depthTop,
              depthBottom,
            );
          }}
        />
        <div className="fieldSampleControlArea">
          <span className="fieldSampleLabel">Tests to Use:</span>
          <Autocomplete
            multiple
            id="testsToUse"
            options={selectOptions}
            getOptionLabel={((option) => option.label)}
            defaultValue={[]}
            onChange={(event, newValue) => onListChange(event, newValue)}
            value={getTestsAssigned()}
            disabled={matchedT4S !== -1 ? testsForSamples[matchedT4S].isHoldFromLab : false}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="standard"
                label="Tests to use"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

FieldSample.propTypes = {
  fieldSample: PropTypes.shape({
    depthTop: PropTypes.number.isRequired,
    depthBottom: PropTypes.number.isRequired,
    bag: PropTypes.number.isRequired,
    jar: PropTypes.number.isRequired,
    vial: PropTypes.number.isRequired,
    sample: PropTypes.string.isRequired,
    ec: PropTypes.number.isRequired,
    ova: PropTypes.number.isRequired,
    comment: PropTypes.string.isRequired,
  }).isRequired,
  tests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  borehole: PropTypes.shape({
    sampleLocationId: PropTypes.string,
    borehole: PropTypes.string,
  }).isRequired,
  renderSampleLabelAndValue: PropTypes.func.isRequired,
};

function FieldSamples({
  fieldSampleList,
  tests,
  borehole,
}) {
  const renderSampleLabelAndValue = (label, value) => (
    <div className="fieldSampleDataPoint">
      <span className="fieldSampleLabel">
        {label}
        :
      </span>
      <span className="fieldSampleValue">{value || 'N/A'}</span>
    </div>
  );

  if (!fieldSampleList) return null;

  return (
    <div className="FieldSamples">
      {fieldSampleList.map((fieldSample) => (
        <FieldSample
          fieldSample={fieldSample}
          tests={tests}
          renderSampleLabelAndValue={renderSampleLabelAndValue}
          borehole={borehole}
          key={fieldSample.depthTop}
        />
      ))}
    </div>
  );
}

FieldSamples.propTypes = {
  fieldSampleList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  borehole: PropTypes.shape({
    sampleLocationId: PropTypes.string,
    borehole: PropTypes.string,
  }).isRequired,
};

export default FieldSamples;
