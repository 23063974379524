import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

function UnsubmittedCocRow({ unsubmittedCoc, cocClick }) {
  const {
    siteName,
  } = unsubmittedCoc;

  return (
    <div className="UnsubmittedCocRow">
      <span>{siteName}</span>
      <Button
        onClick={() => cocClick(unsubmittedCoc)}
      >
        Edit COC
      </Button>
    </div>
  );
}

UnsubmittedCocRow.propTypes = {
  unsubmittedCoc: PropTypes.shape({
    siteName: PropTypes.string,
  }).isRequired,
  cocClick: PropTypes.func.isRequired,
};

export default UnsubmittedCocRow;
