/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import BackupIcon from '@mui/icons-material/Backup';
import SaveIcon from '@mui/icons-material/Save';
import {
  Box,
  MenuItem,
  TextField,
  Button,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import BoreholePagination from './BoreholePagination';
import useSiteForReviewProvider from '../../../hooks/useSiteForReviewProvider';
import useWebService from '../../../hooks/useWebService';
import useCocForm from '../../../hooks/useCocForm';
import * as gqlQuery from './queries';

import './CoCPage.css';

function CoCPage({
  toggleLabCoC,
  siteName,
  boreholes,
  initialize,
  initialState = null,
  updateSubmissionList,
  siteId,
}) {
  const { clearSiteForReview } = useSiteForReviewProvider();
  const { getGqlClient } = useWebService();

  const {
    changeForm,
    getFormValue,
    submitCoc,
    initializeFormTo,
    calculateTests,
  } = useCocForm();

  useEffect(() => {
    if (initialState) {
      initializeFormTo(initialState);
    }
  }, [initialState]);

  const gqlClient = getGqlClient();
  const [provinces, setProvinces] = useState([]);
  const [labs, setLabs] = useState([]);

  // eslint-disable-next-line no-undef
  const [tests, setTests] = useState(JSON.parse(localStorage
    .getItem('smaSampleTests')) || []);

  const turnaroundTimes = ['Seven days', 'Two days', 'One day', 'Immediately'];
  const [errorMessge, setErrorMessage] = useState('');

  // TODO - get these from the database
  const returnToAllBoreholeLogs = () => {
    toggleLabCoC();
    clearSiteForReview();
  };

  async function getCOCPageData() {
    return gqlClient.query(
      gqlQuery.getLabPageData,
      {},
      { fetchPolicy: 'no-cache' },
    );
  }

  const fetchDataAndPopulate = () => {
    getCOCPageData().then((result) => {
      if (provinces.length === 0 && result.data.getProvinces.length > 0) {
        setProvinces(result.data.getProvinces);
      }
      const labCompanies = [];

      result.data.getSampleTests.forEach((sampleTest) => {
        if (!labCompanies.includes(sampleTest.company)) {
          labCompanies.push(sampleTest.company);
        }
      });
      setLabs(labCompanies);
      if (result.data.getSampleTests.length > 0) {
        setTests(result.data.getSampleTests);
      }
    });
  };

  useEffect(() => {
    fetchDataAndPopulate();
  }, []);

  return (
    <div className="CocPage">
      {errorMessge && (
        <div className="coclabErrorMessage">
          {errorMessge}
        </div>
      )}
      <Grid>
        <Row>
          <Col xs={6} span={6}>
            <Button
              onClick={returnToAllBoreholeLogs}
              startIcon={<ArrowBackIcon />}
              className="backButton"
            >
              Go Back to All Borehole Logs
            </Button>
          </Col>
        </Row>
        <Box style={{ padding: '10px', margin: '20px 0' }}>
          <Row>
            <Col md={5}>
              <h3 className="LabChainOfCustodyText">Lab Chain of Custody</h3>
              <h3 className="SiteNameHeaderText">{siteName}</h3>
            </Col>
            <Col md={4}>
              <div style={{ margin: '10px 10px 0 10px' }}>
                <Tooltip title={`NOTE: Submitting a coc will submit all borehole logs associated with ${siteName}`}>
                  <Button
                    onClick={() => submitCoc({
                      setErrorMessage,
                      returnToAllBoreholeLogs,
                      updateSubmissionList,
                      boreholes,
                      siteId,
                      finalSubmit: true,
                    })}
                    endIcon={<BackupIcon />}
                    variant="contained"
                    size="large"
                    disabled={!getFormValue('testsForSamples').length
                      || !getFormValue('testsForSamples').find((t4s) => t4s.testList.length > 0)}
                  >
                    Submit CoC
                  </Button>
                  <Button
                    onClick={() => submitCoc({
                      setErrorMessage,
                      returnToAllBoreholeLogs,
                      updateSubmissionList,
                      boreholes,
                      siteId,
                      finalSubmit: false,
                    })}
                    endIcon={<SaveIcon />}
                    variant="contained"
                    size="large"
                    disabled={!getFormValue('testsForSamples').length
                      || !getFormValue('testsForSamples').find((t4s) => t4s.testList.length > 0)}
                  >
                    Save for later
                  </Button>
                </Tooltip>
              </div>
            </Col>
            <Col md={2} style={{ margin: '10px 10px 0 10px' }} />
          </Row>
          <Box>
            <Row style={{ marginTop: '10px' }}>
              <Col md={3}>
                <h4 className="LabMetadataText">Lab metadata</h4>
              </Col>
              <Col md={3}>
                <InputLabel id="province-select-label">Province</InputLabel>
                <Select
                  className="selectFullWidth"
                  labelId="province-select-label"
                  id="province-select"
                  value={getFormValue('province')}
                  onChange={(event) => changeForm('province', event.target.value)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {
                    provinces.map((provinceFromProvices) => (
                      <MenuItem
                        key={provinceFromProvices.provinceName}
                        value={provinceFromProvices.provinceName}
                      >
                        {provinceFromProvices.provinceName}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Col>
              <Col md={3}>
                <InputLabel id="lab-select-label">Lab</InputLabel>
                <Select
                  className="selectFullWidth"
                  id="lab-select"
                  labelId="lab-select-label"
                  onChange={(event) => changeForm('currentLab', event.target.value)}
                  value={getFormValue('currentLab')}
                >
                  <MenuItem value="">Select</MenuItem>
                  {
                    labs.map((lab) => (
                      <MenuItem key={lab} value={lab}>{lab}</MenuItem>
                    ))
                  }
                </Select>
              </Col>
              <Col md={3}>
                <InputLabel id="turnaround-select-label">Turnaround Time</InputLabel>
                <Select
                  className="selectFullWidth"
                  value={getFormValue('turnAroundTime')}
                  label="Turnaround Time"
                  id="turnaround-select"
                  onChange={(event) => changeForm('turnAroundTime', event.target.value)}
                  labelId="province-select-label"
                >
                  <MenuItem value="">Select</MenuItem>
                  {
                    turnaroundTimes.map((time) => (
                      <MenuItem key={time} value={time}>{time}</MenuItem>
                    ))
                  }
                </Select>
              </Col>
            </Row>

            <Row style={{ marginTop: '15px' }}>
              <Col md={4}>
                <TextField
                  id="cocLabInput"
                  label="Invoice Name"
                  classes="cocLabInput"
                  value={getFormValue('invoiceName')}
                  onChange={(event) => changeForm('invoiceName', event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="reportName"
                  label="Report Name"
                  classes="cocLabInput"
                  value={getFormValue('reportName')}
                  onChange={(event) => changeForm('reportName', event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="additionalReportName"
                  label="Additional Report Name"
                  classes="cocLabInput"
                  value={getFormValue('additionalReportName')}
                  onChange={(event) => changeForm('additionalReportName', event.target.value)}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col md={4}>
                <TextField
                  id="invoiceAddress"
                  label="Invoice Address"
                  classes="cocLabInput"
                  value={getFormValue('invoiceAddress')}
                  onChange={(event) => changeForm('invoiceAddress', event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="reportAddress"
                  label="Report Address"
                  classes="cocLabInput"
                  value={getFormValue('reportAddress')}
                  onChange={(event) => changeForm('reportAddress', event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="additionalReportAddress"
                  label="Additional Report Address"
                  classes="cocLabInput"
                  value={getFormValue('additionalReportAddress')}
                  onChange={(event) => changeForm('additionalReportAddress', event.target.value)}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col md={4}>
                <TextField
                  id="invoicePhone"
                  label="Invoice Phone"
                  classes="cocLabInput"
                  value={getFormValue('invoicePhone')}
                  onChange={(event) => changeForm('invoicePhone', event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="reportPhone"
                  label="Report Phone"
                  classes="cocLabInput"
                  value={getFormValue('reportPhone')}
                  onChange={(event) => changeForm('reportPhone', event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="additionalReportPhone"
                  label="Additional Report Phone"
                  classes="cocLabInput"
                  value={getFormValue('additionalReportPhone')}
                  onChange={(event) => changeForm('additionalReportPhone', event.target.value)}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '15px', marginBottom: '15px' }}>
              <Col md={4}>
                <TextField
                  id="invoiceEmail"
                  label="Invoice Email"
                  classes="cocLabInput"
                  value={getFormValue('invoiceEmail')}
                  onChange={(event) => changeForm('invoiceEmail', event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="reportEmail"
                  label="Report Email"
                  classes="cocLabInput"
                  value={getFormValue('reportEmail')}
                  onChange={(event) => changeForm('reportEmail', event.target.value)}
                />
              </Col>
              <Col md={4}>
                <TextField
                  id="additionalReportEmail"
                  label="Additional Report Email"
                  classes="cocLabInput"
                  value={getFormValue('additionalReportEmail')}
                  onChange={(event) => changeForm('additionalReportEmail', event.target.value)}
                />
              </Col>
            </Row>
          </Box>
          <BoreholePagination
            className="boreholePagination"
            boreholes={boreholes}
            siteName={siteName}
            initialize={initialize}
            tests={calculateTests(tests)}
          />
        </Box>
      </Grid>
    </div>
  );
}

CoCPage.propTypes = {
  toggleLabCoC: PropTypes.func.isRequired,
  updateSubmissionList: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  siteName: PropTypes.string.isRequired,
  boreholes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  initialState: PropTypes.func,
  siteId: PropTypes.string.isRequired,
};

CoCPage.defaultProps = {
  initialState: null,
};

export default CoCPage;
