/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const getUnsubmittedCocLabs = gql`
  query {
    getUnsubmittedCocLabs {
      cocLab {
        id
        labId
        invoiceName
        reportName
        additionalReportName
        invoiceAddress
        reportAddress
        additionalReportAddress
        invoicePhone
        reportPhone
        additionalReportPhone
        invoiceEmail
        reportEmail
        province
        turnAroundTime
        lab
        additionalReportEmail
      }
      fieldSamples {
        id
        sampleLocationId
        sampleId
        depthTop
        depthBottom
        comment
        ec
        ova
        bag
        jar
        vial
        lab
      }
      cocLabFieldSamples {
        labHold
        coclabsId
        fieldSampleId
        testId
      }
      siteName
      siteId
    }
  }
`;
