import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  Button,
  Alert,
} from 'react-bootstrap';
import LoadingSpinner from '../../common/LoadingSpinner';
import './UnsubmittedItems.css';

function UnsubmittedItems({
  loading,
  sortedItems,
  errString,
  duplicateEntity,
  editEntity,
  handleShow,
  submitEntity,
  handleLabCoC,
  handleSiteReview,
  handleBulkShow,
}) {
  if (sortedItems.length === 0) {
    return (<span>No logs ready for submission</span>);
  }

  const onReview = (index, e) => {
    e.stopPropagation();
    handleSiteReview(index);
  };

  const onBulkShow = (index, e) => {
    e.stopPropagation();
    handleBulkShow(index);
  };

  const onSubmitEntity = (item, e) => {
    e.stopPropagation();
    submitEntity(item);
  };

  const onHandleShow = (item, e) => {
    e.stopPropagation();
    handleShow(item.id);
  };

  const onDuplicateEntry = (item, e) => {
    e.stopPropagation();
    duplicateEntity(item.id);
  };

  const onEditEntity = (item, e) => {
    e.stopPropagation();
    editEntity(item.id, 4);
  };

  return (
    <div className="unsubmittedItems">
      {loading && <LoadingSpinner centered />}
      {errString && <Alert bsStyle="danger">{errString}</Alert> }
      {sortedItems?.map(([key, value], index) => (
        <Accordion key={value}>
          <AccordionSummary style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <div className="PanelTitle" style={{ order: 0 }}>
              {key === 'undefined' ? 'Site Name Not Found' : key }
            </div>
            <div style={{ order: 1 }}>
              <Button
                bsStyle="warning"
                className="UnsubmittedButtons"
                style={{ margin: '2.5px' }}
                onClick={() => handleLabCoC(index)}
              >
                Lab CoC
              </Button>

              <Button
                bsStyle="info"
                style={{ margin: '2.5px' }}
                className="UnsubmittedButtons"
                onClick={(e) => onReview(index, e)}
              >
                Review Site
              </Button>
              <Button
                bsStyle="danger"
                className="UnsubmittedButtonDelete"
                style={{ margin: '2.5px' }}
                onClick={(e) => onBulkShow(index, e)}
              >
                Delete All
              </Button>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Accordion>
              <AccordionDetails>
                {value.map((itm) => (
                  <Accordion key={itm.id}>
                    <AccordionSummary style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                    >
                      <div className="unsubmittedPanelContent">
                        <div className="PanelTitle" style={{ order: 0 }}>
                          <h5 className="boreholeName">{itm.doc.name}</h5>
                          <h6>{new Date(itm.doc.sampleLocDatetime).toLocaleString()}</h6>
                          {itm.doc.edit_time
                          && (
                          <p style={{ fontSize: '10px', color: 'green' }}>
                            {`Edited On ${new Date(itm.doc.edit_time).toLocaleString()}`}
                          </p>
                          )}
                          {itm.doc.submitted
                          && (
                          <p style={{ fontSize: '10px', color: 'green' }}>
                            Submitted On
                            {new Date(itm.doc.submitted).toLocaleString()}
                          </p>
                          )}
                        </div>
                        <div style={{ order: 1 }}>
                          {(itm.doc.submitted === undefined || itm.doc.submitted === '')
                          && (
                            <Button
                              style={{
                                marginLeft: '10px', width: '80px', height: '40px', marginTop: '10px',
                              }}
                              onClick={(e) => onSubmitEntity(itm, e)}
                              disabled={loading}
                              className="UnsubmittedButtons"
                            >
                              Submit
                            </Button>
                          )}
                          <Button
                            bsStyle="info"
                            className="UnsubmittedButtons"
                            style={{
                              marginLeft: '10px', width: '90px', height: '40px', marginTop: '10px',
                            }}
                            onClick={(e) => onDuplicateEntry(itm, e)}
                          >
                            Duplicate
                          </Button>
                          <Button
                            style={{
                              marginLeft: '10px', width: '80px', height: '40px', marginTop: '10px',
                            }}
                            className="UnsubmittedButtons"
                            bsStyle="info"
                            onClick={(e) => onEditEntity(itm, e)}
                          >
                            Edit
                          </Button>
                          <Button
                            style={{
                              marginLeft: '10px', width: '80px', height: '40px', marginTop: '10px',
                            }}
                            bsStyle="danger"
                            className="UnsubmittedButtonDelete"
                            onClick={(e) => onHandleShow(itm, e)}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <pre>
                        {JSON.stringify(itm.doc, null, 2)}
                      </pre>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

UnsubmittedItems.propTypes = {
  loading: PropTypes.bool.isRequired,
  sortedItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  errString: PropTypes.string.isRequired,
  duplicateEntity: PropTypes.func.isRequired,
  editEntity: PropTypes.func.isRequired,
  handleShow: PropTypes.func.isRequired,
  submitEntity: PropTypes.func.isRequired,
  handleLabCoC: PropTypes.func.isRequired,
  handleSiteReview: PropTypes.func.isRequired,
  handleBulkShow: PropTypes.func.isRequired,
};

export default UnsubmittedItems;
